













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Watch, Vue } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  minValue,
  numeric,
  minLength,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import routeController, { Data } from '@/app/ui/controllers/RouteController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import ProgramTextInput from '../components/ProgramTextInput/index.vue'
import ProgramDropdown from '../components/ProgramDropdown/index.vue'
import ProgramDatePicker from '../components/ProgramDatePicker/index.vue'
import ProgramOptionRadio from '../components/ProgamOptionRadio/index.vue'
import Note from '@/app/ui/components/Note/Note.vue'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import { Program } from '@/domain/entities/Program'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticPoint'
import {
  GroupOption,
  LabelValue,
} from '@/app/ui/components/OptionBox/interfaces'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import { PARCEL_POINT_NAME } from '@/app/infrastructures/misc/Constants/programaticPoint'
import useProgrammaticPoint from '@/app/ui/views/Saldo/ProgramaticPoint/useCase'
import ProgramCheckBox from '../components/ProgramCheckBox/index.vue'
import ProgramMultiTagTextInput from '../components/ProgramMultiTagTextInput/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import { PointPurpose } from '@/domain/entities/PointPurpose'
import ProgramMultiSelect from '../components/ProgramMultiSelect/index.vue'

interface DropdownInterface {
  value: string | number
  label: string
  $isDisabled?: boolean
}

interface EditFormInterface {
  programName: string
  userTarget: LabelValue<constantData.UserType> | null
  fileUsers: File
  triggerBy: LabelValue<string> | null
  routes: Data[]
  scheduleDate: string
  scheduleTime: string
  invoiceSource: Array<string>
  paymentMethod: Array<string>
  minimumTransaction: number
  purpose: Array<string>
  deviceIdLimitCustomer: null | number | undefined,
  pointValueType: string
  // pointAmount: number
  percentOfTransaction: number
  maxAmountGiven: number
  pointExpirationType: string
  expiredDate: string | null
  expiryDay: number
  // limitPerDay: number
  // limitPerUser: number
  budgetSource: LabelValue<string> | null
  budgetAmount: null | number | undefined
  budgetSourceOther: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  membershipLevel: { label: string, value: number }[]
  extraRulesActive: boolean
  totalAmountTransaction: null | number | undefined
  totalAmountTransactionTime: LabelValue<string> | null
  totalNumberTransaction: null | number | undefined
  totalNumberTransactionTime: LabelValue<string> | null
  logicType: string
  statusType: string[]
  shipmentType: string[]
  productType: string[]
  minimumAmount: null | number | undefined
  pointValue: LabelValue<string | number> | null
  pointValueAmountForInviter: null | number | undefined
  pointValueAmountForInviterDisburseTo: LabelValue<string> | null
  pointValueAmountForInviterApprovalType: LabelValue<string> | null
  pointValueAmountForInviterDisburseType: LabelValue<string> | null
  pointValueAmountForInviterDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValueAmountForInviterDisburseTypeSpecificDate: LabelValue<number> | null
  pointValueAmountForInvitee: null | number | undefined
  pointValueAmountForInviteeDisburseTo: LabelValue<string> | null
  pointValueAmountForInviteeApprovalType: LabelValue<string> | null
  pointValueAmountForInviteeDisburseType: LabelValue<string> | null
  pointValueAmountForInviteeDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValueAmountForInviteeDisburseTypeSpecificDate: LabelValue<number> | null
  pointValueAmountForGeneral: null | number | undefined
  pointValueAmountForGeneralDisburseTo: LabelValue<string> | null
  pointValueAmountForGeneralApprovalType: LabelValue<string> | null
  pointValueAmountForGeneralDisburseType: LabelValue<string> | null
  pointValueAmountForGeneralDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValueAmountForGeneralDisburseTypeSpecificDate: LabelValue<number> | null
  pointValuePercentageForInviter: null | number | undefined
  pointValuePercentageForInviterMax: null | number | undefined
  pointValuePercentageForInviterDisburseTo: LabelValue<string> | null
  pointValuePercentageForInviterApprovalType: LabelValue<string> | null
  pointValuePercentageForInviterDisburseType: LabelValue<string> | null
  pointValuePercentageForInviterDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValuePercentageForInviterDisburseTypeSpecificDate: LabelValue<number> | null
  pointValuePercentageForInvitee: null | number | undefined
  pointValuePercentageForInviteeMax: null | number | undefined
  pointValuePercentageForInviteeDisburseTo: LabelValue<string> | null
  pointValuePercentageForInviteeApprovalType: LabelValue<string> | null
  pointValuePercentageForInviteeDisburseType: LabelValue<string> | null
  pointValuePercentageForInviteeDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValuePercentageForInviteeDisburseTypeSpecificDate: LabelValue<number> | null
  pointValuePercentageForGeneral: null | number | undefined
  pointValuePercentageForGeneralMax: null | number | undefined
  pointValuePercentageForGeneralDisburseTo: LabelValue<string> | null
  pointValuePercentageForGeneralApprovalType: LabelValue<string> | null
  pointValuePercentageForGeneralDisburseType: LabelValue<string> | null
  pointValuePercentageForGeneralDisburseTypeShipmentStatus: LabelValue<string> | null
  pointValuePercentageForGeneralDisburseTypeSpecificDate: LabelValue<number> | null
  pointDisburseType: LabelValue<string> | null
  shipmentStatus: LabelValue<string> | null
  specificDate: LabelValue<string|number> | null
  pointDisburseLimitPerProgram: LabelValue<string> | null
  totalNumberDisbursePerProgram: null | number | undefined
  totalAmountDisbursePerProgram: null | number | undefined
  pointDisburseLimitPerUser: LabelValue<string> | null
  totalNumberDisbursePerUser: null | number | undefined
  totalAmountDisbursePerUser: null | number | undefined
  pointPurposeState: { checked: boolean; value: number; label: string }[]
}

interface ValidationsInterface {
  form: {
    programName: {
      required: () => ValidationRule
      maxLength: ValidationRule
    }
    userTarget: {
      required: () => ValidationRule
    }
    membershipLevel: {
      required: ValidationRule
    }
    triggerBy: {
      required: () => ValidationRule
    }
    scheduleDate: {
      required?: () => ValidationRule
    }
    scheduleTime: {
      required?: () => ValidationRule
    }
    purpose: Record<string, never>
    invoiceSource: {
      required: ValidationRule
    }
    minimumTransaction: {
      required: ValidationRule
      minValue: ValidationRule
    }
    paymentMethod: {
      required: ValidationRule
      minLength: ValidationRule
    }
    // pointAmount: {
    //   required: ValidationRule
    //   minValue: ValidationRule
    //   numeric: () => ValidationRule
    // }
    percentOfTransaction: {
      required: ValidationRule
      minValue: ValidationRule
      numeric: () => ValidationRule
    }
    maxAmountGiven: {
      required: ValidationRule
      minValue: ValidationRule
      numeric: () => ValidationRule
    }
    expiredDate: {
      required: ValidationRule
    }
    expiryDay: {
      required: ValidationRule
      minValue: ValidationRule
    }
    // limitPerDay: {
    //   required: () => ValidationRule
    //   minValue: ValidationRule
    // }
    // limitPerUser: {
    //   required: () => ValidationRule
    //   minValue: ValidationRule
    // }
    budgetAmount: {
      required: ValidationRule
      minValue: ValidationRule | boolean
    }
    budgetSourceOther: {
      required: ValidationRule
    }
    startDate: {
      required?: () => ValidationRule
    }
    startTime: {
      required?: () => ValidationRule
    }
    endDate: {
      required?: () => ValidationRule
    }
    endTime: {
      required?: () => ValidationRule
    }
    fileUsers: {
      name: {
        required: ValidationRule
      }
    }
    totalAmountTransaction: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountTransactionTime: {
      required: ValidationRule;
    }
    totalNumberTransaction: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalNumberTransactionTime: {
      required: ValidationRule;
    }
    logicType: {
      required: ValidationRule
    }
    statusType: {
      required: ValidationRule
    }
    shipmentType: {
      required: ValidationRule
    }
    productType: {
      required: ValidationRule
    }
    minimumAmount: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    deviceIdLimitCustomer: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValue: {
      required: () => ValidationRule
    }
    pointValueAmountForInviter: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForInviterDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForInviterApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValueAmountForInvitee: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForInviteeDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForInviteeApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValueAmountForGeneral: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForGeneralDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForGeneralApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForInviter: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviterMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviterDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForInviterApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForInvitee: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviteeMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviteeDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForGeneral: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForGeneralDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForGeneralDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    totalNumberDisbursePerProgram: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountDisbursePerProgram: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalNumberDisbursePerUser: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountDisbursePerUser: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    ProgramTextInput,
    ProgramDropdown,
    ProgramDatePicker,
    ProgramOptionRadio,
    OptionBox,
    LoadingOverlay,
    RadioButton,
    Note,
    Toggle,
    ModalAction,
    ProgramCheckBox,
    ProgramMultiTagTextInput,
    IconWarningTriangle,
    ProgramMultiSelect,
  },
})
export default class CreateProgram extends Vue {
  controller = controller
  useActionProgrammaticPoint = useProgrammaticPoint()
  routeController = routeController
  maxCount = 30
  form: EditFormInterface = {
    programName: '',
    userTarget: <LabelValue<constantData.UserType> | null>null,
    fileUsers: new File([], ''),
    triggerBy: <LabelValue<string> | null>null,
    routes: <Data[]>[],
    scheduleDate: '',
    scheduleTime: '',
    invoiceSource: <Array<string>>[],
    paymentMethod: <Array<string>>[],
    minimumTransaction: 0,
    purpose: <Array<string>>[],
    deviceIdLimitCustomer: null,
    pointValueType: 'fixed',
    // pointAmount: 0,
    percentOfTransaction: 0,
    maxAmountGiven: 0,
    pointExpirationType: 'days',
    expiredDate: <string | null>null,
    expiryDay: 0,
    // limitPerDay: 0,
    // limitPerUser: 0,
    budgetSource: <LabelValue<string> | null>null,
    budgetAmount: <null | number | undefined>null,
    budgetSourceOther: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    membershipLevel: <{ label: string, value: number }[]>[],
    extraRulesActive: <boolean>false,
    totalAmountTransaction: <null|number|undefined>null,
    totalAmountTransactionTime: <LabelValue<string>|null>null,
    totalNumberTransaction: <null|number|undefined>null,
    totalNumberTransactionTime: <LabelValue<string>|null>null,
    logicType: '',
    statusType: <Array<string>>[],
    shipmentType: <Array<string>>[],
    productType: <Array<string>>[],
    minimumAmount: <null|number|undefined>null,
    pointValue: <LabelValue<string|number>|null>null,
    pointValueAmountForInviter: <null|number|undefined>null,
    pointValueAmountForInviterDisburseTo: <LabelValue<string>|null>null,
    pointValueAmountForInviterApprovalType: <LabelValue<string>|null>null,
    pointValueAmountForInviterDisburseType: <LabelValue<string>|null>null,
    pointValueAmountForInviterDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValueAmountForInviterDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointValueAmountForInvitee: <null|number|undefined>null,
    pointValueAmountForInviteeDisburseTo: <LabelValue<string>|null>null,
    pointValueAmountForInviteeApprovalType: <LabelValue<string>|null>null,
    pointValueAmountForInviteeDisburseType: <LabelValue<string>|null>null,
    pointValueAmountForInviteeDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValueAmountForInviteeDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointValueAmountForGeneral: <null|number|undefined>null,
    pointValueAmountForGeneralDisburseTo: <LabelValue<string>|null>null,
    pointValueAmountForGeneralApprovalType: <LabelValue<string>|null>null,
    pointValueAmountForGeneralDisburseType: <LabelValue<string>|null>null,
    pointValueAmountForGeneralDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValueAmountForGeneralDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointValuePercentageForInviter: <null|number|undefined>null,
    pointValuePercentageForInviterMax: <null|number|undefined>null,
    pointValuePercentageForInviterDisburseTo: <LabelValue<string>|null>null,
    pointValuePercentageForInviterApprovalType: <LabelValue<string>|null>null,
    pointValuePercentageForInviterDisburseType: <LabelValue<string>|null>null,
    pointValuePercentageForInviterDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValuePercentageForInviterDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointValuePercentageForInvitee: <null|number|undefined>null,
    pointValuePercentageForInviteeMax: <null|number|undefined>null,
    pointValuePercentageForInviteeDisburseTo: <LabelValue<string>|null>null,
    pointValuePercentageForInviteeApprovalType: <LabelValue<string>|null>null,
    pointValuePercentageForInviteeDisburseType: <LabelValue<string>|null>null,
    pointValuePercentageForInviteeDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValuePercentageForInviteeDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointValuePercentageForGeneral: <null|number|undefined>null,
    pointValuePercentageForGeneralMax: <null|number|undefined>null,
    pointValuePercentageForGeneralDisburseTo: <LabelValue<string>|null>null,
    pointValuePercentageForGeneralApprovalType: <LabelValue<string>|null>null,
    pointValuePercentageForGeneralDisburseType: <LabelValue<string>|null>null,
    pointValuePercentageForGeneralDisburseTypeShipmentStatus: <LabelValue<string>|null>null,
    pointValuePercentageForGeneralDisburseTypeSpecificDate: <LabelValue<number>|null>null,
    pointDisburseType: <LabelValue<string>|null>null,
    shipmentStatus: <LabelValue<string>|null>null,
    specificDate: <LabelValue<string|number>|null>null,
    pointDisburseLimitPerProgram: <LabelValue<string>|null>null,
    totalNumberDisbursePerProgram: <null|number|undefined>null,
    totalAmountDisbursePerProgram: <null|number|undefined>null,
    pointDisburseLimitPerUser: <LabelValue<string>|null>null,
    totalNumberDisbursePerUser: <null|number|undefined>null,
    totalAmountDisbursePerUser: <null|number|undefined>null,
    pointPurposeState: <{ checked: boolean; value: number; label: string} []>[],
  }
  pointPurposeChanged = false
  tagChanged = {
    statusType: false,
    shipmentType: false,
    productType: false,
  }
  successModal = false
  showModalConfirm = false
  todayDate = dayjs().format('YYYY-MM-DD')
  disableLimitUser = false
  constants = constantData
  labelOfPercentagePoint = 'Percentage of Total Transaction'
  budgetAmountActive = false
  budgetAmountDisabledOnEdit = false
  extraRulesActive = false
  deviceIdLimitCustomerActive = false
  extraRulesDisabledOnEdit = false
  totalAmountTransactionActive = false
  totalNumberTransactionActive = false
  pointValueAmountForInviterActive = false
  pointValueAmountForInviteeActive = false
  pointValueAmountForGeneralActive = false
  pointValuePercentageForInviterActive = false
  pointValuePercentageForInviterMaxActive = false
  pointValuePercentageForInviteeActive = false
  pointValuePercentageForInviteeMaxActive = false
  pointValuePercentageForGeneralActive = false
  pointValuePercentageForGeneralMaxActive = false
  totalNumberDisbursePerProgramActive = false
  totalAmountDisbursePerProgramActive = false
  totalNumberDisbursePerUserActive = false
  totalAmountDisbursePerUserActive = false
  triggeredByHasChanged = false

  created(): void {
    routeController.getCityList()
    controller.getTriggers()
    controller.getPointPurposes()
    controller.getPaymentMethodList()
    controller.getMembershipLevelList()

    EventBus.$on(EventBusConstants.SAVE_PROGRAM_SUCCESS, () => {
      this.successModal = true
    })
  }

  @Watch('form', { deep: true })
  onFormChanged(val: EditFormInterface): void {
    if (val.triggerBy?.value === 'ON_SCHEDULE') {
      this.form.startDate = this.programDetail.startDate
        ? dayjs(this.programDetail.startDate).format('YYYY-MM-DD')
        : ''
      this.form.startTime = this.programDetail.startDate
        ? dayjs(this.programDetail.startDate).format('HH:mm:ss')
        : ''
      this.form.endDate = this.programDetail.endDate
        ? dayjs(this.programDetail.endDate).format('YYYY-MM-DD')
        : ''
      this.form.endTime = this.programDetail.endDate
        ? dayjs(this.programDetail.endDate).format('HH:mm:ss')
        : ''
      this.budgetAmountActive = false
      this.form.budgetAmount = null
    } else {
      this.form.scheduleDate = this.programDetail.scheduledAt
        ? dayjs(this.programDetail.scheduledAt).format('YYYY-MM-DD')
        : ''
      this.form.scheduleTime = this.programDetail.scheduledAt
        ? dayjs(this.programDetail.scheduledAt).format('HH:mm:ss')
        : ''
    }
  }

  get pointPurposeState(): { checked: boolean; value: number; label: string }[] {
    return this.form.pointPurposeState
  }

  @Watch('controller.pointPurposes')
  onPointPurposesChanged(newVal: PointPurpose[]): void {
    this.form.pointPurposeState = <{checked: boolean, value: number, label: string}[]>newVal.map((item) => {
      return {
        checked: false,
        value: 0,
        label: item.id?.toLowerCase(),
      }
    })
    controller.getProgramDetail(this.$route.params.id)
  }

  @Watch('form.triggerBy', { deep: true })
  onTriggerChanged(
    trigger: LabelValue<string>,
    oldTrigger: DropdownInterface
  ): void {
    // Select Complete Profile
    // if (
    //   trigger &&
    //   trigger.value === constantData.TriggerType.COMPLETE_PROFILE
    // ) {
    //   this.form.limitPerUser = 1
    //   this.disableLimitUser = true
    // }
    // // UnSelect Complete Profile
    // if (
    //   oldTrigger &&
    //   oldTrigger.value === constantData.TriggerType.COMPLETE_PROFILE
    // ) {
    //   this.form.limitPerUser = 0
    //   this.disableLimitUser = false
    // }

    if (
        trigger?.value === constantData.TriggerType.TRANSACTION_DONE &&
        this.triggeredByHasChanged
      ) {
      this.form.statusType = ['POD']
      this.form.shipmentType = ['AG', 'AD', 'ARA', 'ARB', 'AP', 'AS', 'AO']
      this.form.productType = ['REGPACK', 'JAGOPACK', 'BOSSPACK', 'INTERPACK', 'BIGPACK']
      this.form.minimumAmount = 0
    }
    else if (
      trigger?.value !== constantData.TriggerType.TRANSACTION_DONE &&
      this.triggeredByHasChanged
      ) {
      this.form.statusType = []
      this.form.shipmentType = []
      this.form.productType = []
      this.form.minimumAmount = null
    }
  }

  @Watch('form.userTarget', { deep: true })
  onUserTargetChanged(newVal: Record<string, string | boolean> | null): void {
    // if (newVal && newVal['value'] === constantData.UserType.MEMBERSHIP) {
    //   this.form.pointValueType = constantData.PointValueType.PERCENTAGE
    //   this.labelOfPercentagePoint = 'Percentage of Total Shipping Fee'
    // } else {
    //   this.labelOfPercentagePoint = 'Percentage of Total Transaction'
    // }
  }

  @Watch('controller.programDetail', { deep: true })
  onControllerProgramDetailChanged(val: Program): void {
    this.form = {
      ...this.form,
      programName: val.name || '',
      userTarget:
        this.constants.TARGET_OPTIONS.find(item => item.value === val.target) ||
        null,
      fileUsers: new File([], val.selectedUsers as string),
      triggerBy:
        this.triggerOptionsNew.find(item => item.value === val.triggerBy) ||
        null,
      routes: this.routeOptions.filter(item =>
        val.routes?.map(r => r.id).includes(Number(item.value))
      ),
      scheduleDate: val.scheduledAt
        ? dayjs(val.scheduledAt).format('YYYY-MM-DD')
        : '',
      scheduleTime: val.scheduledAt
        ? dayjs(val.scheduledAt).format('HH:mm:ss')
        : '',
      invoiceSource: val.invoiceSource ? val.invoiceSource.split(',') : [],
      paymentMethod: val.paymentMethodUsed
        ? val.paymentMethodUsed.split(',')
        : [],
      minimumTransaction: val.minimumTransaction || 0,
      purpose: val.purpose ? val.purpose.split(',') : ([] as Array<string>),
      pointValueType:
        val.percentOfTransaction && val.percentOfTransaction > 0
          ? constantData.PointValueType.PERCENTAGE
          : constantData.PointValueType.FIXED,
      // pointAmount: val.points || 0,
      percentOfTransaction: val.percentOfTransaction || 0,
      maxAmountGiven: val.maxAmountGiven || 0,
      pointExpirationType: val.expiredDate ? 'exact-date' : 'days',
      expiredDate: val.expiredDate
        ? dayjs(val.expiredDate).format('YYYY-MM-DD')
        : null,
      expiryDay: val.expiryDay || 0,
      // limitPerDay: val.limitPerDay || 0,
      // limitPerUser: val.limitPerUser || 0,
      budgetSource:
        this.constants.BUDGET_SOURCE.find(budgetSource => {
          if (val.budgetSource?.startsWith('OTHERS'))
            return budgetSource.value === 'OTHERS'

          return budgetSource.value === val.budgetSource
        }) || null,
      budgetSourceOther: val.budgetSource?.startsWith('OTHERS')
        ? val.budgetSource.substr(7)
        : '',
      budgetAmount: val.budgetAmount,
      startDate: val.startDate ? dayjs(val.startDate).format('YYYY-MM-DD') : '',
      endDate: val.endDate ? dayjs(val.endDate).format('YYYY-MM-DD') : '',
      startTime: val.startDate ? dayjs(val.startDate).format('HH:mm:ss') : '',
      endTime: val.endDate ? dayjs(val.endDate).format('HH:mm:ss') : '',
      membershipLevel: val.membershipLevel || [],
      totalAmountTransaction: val.totalAmountTransaction || null,
      totalAmountTransactionTime: this.constants.TIME_FREQ.find(item => item.value === val.totalAmountTransactionTime) || null,
      totalNumberTransaction: val.totalNumberTransaction || null,
      totalNumberTransactionTime: this.constants.TIME_FREQ.find(item => item.value === val.totalNumberTransactionTime) || null,
      logicType: val.logicType || '',
      statusType: val.statusType || [],
      shipmentType: val.shipmentType || [],
      productType: val.productType || [],
      minimumAmount: val.minimumAmount || null,
      pointValue: this.constants.POINT_VALUE.find(item => item.value === val.pointValueOpt) || null,
      pointValueAmountForInviter: val.pointValueAmountForInviter || null,
      pointValueAmountForInviterDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValueAmountForInviterDisburseTo) || null,
      pointValueAmountForInviterApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValueAmountForInviterApprovalType) || null,
      pointValueAmountForInviterDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValueAmountForInviterDisburseType) || null,
      pointValueAmountForInviterDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValueAmountForInviterDisburseStatus) || null,
      pointValueAmountForInviterDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValueAmountForInviterDisburseSpecificDate) || null,
      pointValueAmountForInvitee: val.pointValueAmountForInvitee || null,
      pointValueAmountForInviteeDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValueAmountForInviteeDisburseTo) || null,
      pointValueAmountForInviteeApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValueAmountForInviteeApprovalType) || null,
      pointValueAmountForInviteeDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValueAmountForInviteeDisburseType) || null,
      pointValueAmountForInviteeDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValueAmountForInviteeDisburseStatus) || null,
      pointValueAmountForInviteeDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValueAmountForInviteeDisburseSpecificDate) || null,
      pointValueAmountForGeneral: val.pointValueAmountForGeneral || null,
      pointValueAmountForGeneralDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValueAmountForGeneralDisburseTo) || null,
      pointValueAmountForGeneralApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValueAmountForGeneralApprovalType) || null,
      pointValueAmountForGeneralDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValueAmountForGeneralDisburseType) || null,
      pointValueAmountForGeneralDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValueAmountForGeneralDisburseStatus) || null,
      pointValueAmountForGeneralDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValueAmountForGeneralDisburseSpecificDate) || null,
      pointValuePercentageForInviter: val.pointValuePercentageForInviter || null,
      pointValuePercentageForInviterMax: val.pointValuePercentageForInviterMax || null,
      pointValuePercentageForInviterDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValuePercentageForInviterDisburseTo) || null,
      pointValuePercentageForInviterApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValuePercentageForInviterApprovalType) || null,
      pointValuePercentageForInviterDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValuePercentageForInviterDisburseType) || null,
      pointValuePercentageForInviterDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValuePercentageForInviterDisburseStatus) || null,
      pointValuePercentageForInviterDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValuePercentageForInviterDisburseSpecificDate) || null,
      pointValuePercentageForInvitee: val.pointValuePercentageForInvitee || null,
      pointValuePercentageForInviteeMax: val.pointValuePercentageForInviteeMax || null,
      pointValuePercentageForInviteeDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValuePercentageForInviteeDisburseTo) || null,
      pointValuePercentageForInviteeApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValuePercentageForInviteeApprovalType) || null,
      pointValuePercentageForInviteeDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValuePercentageForInviteeDisburseType) || null,
      pointValuePercentageForInviteeDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValuePercentageForInviteeDisburseStatus) || null,
      pointValuePercentageForInviteeDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValuePercentageForInviteeDisburseSpecificDate) || null,
      pointValuePercentageForGeneral: val.pointValuePercentageForGeneral || null,
      pointValuePercentageForGeneralMax: val.pointValuePercentageForGeneralMax || null,
      pointValuePercentageForGeneralDisburseTo: this.constants.POINT_DISBURSE_TO.find(item => item.value === val.pointValuePercentageForGeneralDisburseTo) || null,
      pointValuePercentageForGeneralApprovalType: this.constants.POINT_APPROVAL_TYPE.find(item => item.value === val.pointValuePercentageForGeneralApprovalType) || null,
      pointValuePercentageForGeneralDisburseType: this.constants.POINT_DISBURSE_TYPE.find(item => item.value === val.pointValuePercentageForGeneralDisburseType) || null,
      pointValuePercentageForGeneralDisburseTypeShipmentStatus: this.constants.SHIPMENT_STATUS.find(item => item.value === val.pointValuePercentageForGeneralDisburseStatus) || null,
      pointValuePercentageForGeneralDisburseTypeSpecificDate: this.constants.SPECIFIC_DATE.find(item => item.value === val.pointValuePercentageForGeneralDisburseSpecificDate) || null,
      pointDisburseLimitPerProgram: this.constants.POINT_DISBURSE_PROGRAM_TIME.find(item => item.value === val.pointDisburseLimitPerProgram) || null,
      totalNumberDisbursePerProgram: val.totalNumberDisbursePerProgram || null,
      totalAmountDisbursePerProgram: val.totalAmountDisbursePerProgram || null,
      pointDisburseLimitPerUser: this.constants.POINT_DISBURSE_USER_TIME.find(item => item.value === val.pointDisburseLimitPerUser) || null,
      totalNumberDisbursePerUser: val.totalNumberDisbursePerUser || null,
      totalAmountDisbursePerUser: val.totalAmountDisbursePerUser || null,
      deviceIdLimitCustomer: val.deviceIdLimitCustomer || null,
    }

    this.deviceIdLimitCustomerActive = Boolean(val.deviceIdLimitCustomer) || false
    this.extraRulesActive = Boolean(val.totalAmountTransaction || val.totalNumberTransaction) || false
    this.totalAmountTransactionActive = Boolean(val.totalAmountTransaction) || false
    this.totalNumberTransactionActive = Boolean(val.totalNumberTransaction) || false

    if (
      val.pointValueAmountForInviter ||
      val.pointValueAmountForInvitee ||
      val.pointValueAmountForGeneral
    ) {
      this.form.pointValue = this.constants.POINT_VALUE[0]

      this.pointValueAmountForInviterActive = Boolean(val.pointValueAmountForInviter)
      this.pointValueAmountForInviteeActive = Boolean(val.pointValueAmountForInvitee)
      this.pointValueAmountForGeneralActive = Boolean(val.pointValueAmountForGeneral)
    }
    else if (
      val.pointValuePercentageForInviter ||
      val.pointValuePercentageForInviterMax ||
      val.pointValuePercentageForInvitee ||
      val.pointValuePercentageForInviteeMax ||
      val.pointValuePercentageForGeneral ||
      val.pointValuePercentageForGeneralMax
    ) {
      this.form.pointValue = this.constants.POINT_VALUE[1]

      this.pointValuePercentageForInviterActive = Boolean(val.pointValuePercentageForInviter)
      this.pointValuePercentageForInviterMaxActive = Boolean(val.pointValuePercentageForInviterMax)
      this.pointValuePercentageForInviteeActive = Boolean(val.pointValuePercentageForInvitee)
      this.pointValuePercentageForInviteeMaxActive = Boolean(val.pointValuePercentageForInviteeMax)
      this.pointValuePercentageForGeneralActive = Boolean(val.pointValuePercentageForGeneral)
      this.pointValuePercentageForGeneralMaxActive = Boolean(val.pointValuePercentageForGeneralMax)
    }

    this.totalNumberDisbursePerProgramActive = Boolean(val.totalNumberDisbursePerProgram)
    this.totalAmountDisbursePerProgramActive = Boolean(val.totalAmountDisbursePerProgram)
    this.totalNumberDisbursePerUserActive = Boolean(val.totalNumberDisbursePerUser)
    this.totalAmountDisbursePerUserActive = Boolean(val.totalAmountDisbursePerUser)

    this.form.pointPurposeState = <{ checked: boolean; value: number; label: string; }[]>this.form.pointPurposeState.map((item) => {
      return Object.entries({...val.pointPurposePercentage}).map(data => {
        if (item.label === data[0]) {
          return {
            ...item,
            value: data[1],
            checked: Boolean(data[1]),
          }
        }
      }).filter(item => item)[0]
    })

    if (
      this.form.budgetAmount &&
      this.form.budgetAmount > 0 &&
      !this.isTriggeredByOnSchedule
    ) {
      this.budgetAmountActive = true
      this.budgetAmountDisabledOnEdit = true
    }
  }

  @Validations()
  validations(): ValidationsInterface {
    let validate = {
      form: {
        programName: { required, maxLength: maxLength(this.maxCount) },
        userTarget: { required },
        membershipLevel: {
          required: requiredIf(
            () => this.isMemberUser
          ),
        },
        triggerBy: { required },
        scheduleDate: {},
        scheduleTime: {},
        purpose: {},
        invoiceSource: {
          required: requiredIf(() => this.triggedByInvoicePaidAll),
        },
        minimumTransaction: {
          required: requiredIf(
            () => this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
          ),
          minValue: minValue(
            this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
              ? 1
              : 0
          ),
        },
        paymentMethod: {
          required: requiredIf(
            () => this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
          ),
          minLength: minLength(
            this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
              ? 1
              : 0
          ),
        },
        // pointAmount: {
        //   required: requiredIf(
        //     () => this.form.pointValueType === constantData.PointValueType.FIXED
        //   ),
        //   minValue: minValue(
        //     this.form.pointValueType === constantData.PointValueType.FIXED
        //       ? 1
        //       : 0
        //   ),
        //   numeric,
        // },
        percentOfTransaction: {
          required: requiredIf(
            () =>
              this.form.pointValueType ===
              constantData.PointValueType.PERCENTAGE
          ),
          minValue: minValue(
            this.form.pointValueType === constantData.PointValueType.PERCENTAGE
              ? 1
              : 0
          ),
          numeric,
        },
        maxAmountGiven: {
          required: requiredIf(
            () =>
              this.form.pointValueType ===
              constantData.PointValueType.PERCENTAGE
          ),
          minValue: minValue(
            this.form.pointValueType === constantData.PointValueType.PERCENTAGE
              ? 1
              : 0
          ),
          numeric,
        },
        expiredDate: {
          required: requiredIf(
            () =>
              this.form.pointExpirationType === 'exact-date'
          ),
        },
        expiryDay: {
          required: requiredIf(
            () =>
              this.form.pointExpirationType === 'days'
          ),
          minValue: minValue(
            this.form.pointExpirationType === 'days'
              ? 1
              : 0
          ),
        },
        // limitPerDay: { required, minValue: minValue(1) },
        // limitPerUser: {
        //   required,
        //   minValue: minValue(1),
        // },
        budgetAmount: {
          required: requiredIf(
            () => this.budgetAmountActive && !this.isTriggeredByOnSchedule
          ),
          minValue:
            this.budgetAmountActive && !this.isTriggeredByOnSchedule
              ? minValue(1)
              : true,
        },
        budgetSourceOther: {
          required: requiredIf(
            () => this.form.budgetSource?.value === 'OTHERS'
          ),
        },
        startDate: {},
        startTime: {},
        endDate: {},
        endTime: {},
        fileUsers: {
          name: {
            required: requiredIf(
              () =>
                this.form.userTarget?.value === constantData.UserType.SELECTED
            ),
          },
        },
        statusType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        shipmentType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        productType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        minimumAmount: {
          required: requiredIf(
            () => this.triggedByTransactionDone && this.form.minimumAmount !== null
          ),
          minValue:
            this.triggedByTransactionDone
              ? minValue(0)
              : true,
        },
        totalAmountTransaction: {
          required: requiredIf(
            () => this.extraRulesTotalAmountValidation
          ),
          minValue:
            this.extraRulesTotalAmountValidation
              ? minValue(1)
              : true,
        },
        totalAmountTransactionTime: {
          required: requiredIf(
            () => this.extraRulesTotalAmountValidation
          )
        },
        totalNumberTransaction: {
          required: requiredIf(
            () => this.extraRulesTotalNumberValidation
          ),
          minValue:
            this.extraRulesTotalNumberValidation
              ? minValue(1)
              : true,
        },
        totalNumberTransactionTime: {
          required: requiredIf(
            () => this.extraRulesTotalNumberValidation
          )
        },
        logicType: {
          required: requiredIf(
            () => this.totalAmountTransactionActive && this.totalNumberTransactionActive
          )
         },
        deviceIdLimitCustomer: {
          required: requiredIf(
            () => this.deviceIdLimitCustomerActive
          ),
          minValue:
            this.deviceIdLimitCustomerActive
              ? minValue(1)
              : true,
        },
        pointValue: { required },
        pointValueAmountForInviter: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          ),
          minValue:
            this.pointValueAmountForInviterValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForInviterDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation && this.pointValueAmountForInviterDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForInviterDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation && this.pointValueAmountForInviterDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValueAmountForInvitee: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          ),
          minValue:
            this.pointValueAmountForInviteeValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForInviteeDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation && this.pointValueAmountForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForInviteeDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation && this.pointValueAmountForInviteeDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValueAmountForGeneral: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          ),
          minValue:
            this.pointValueAmountForGeneralValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForGeneralDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation && this.pointValueAmountForGeneralDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForGeneralDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation && this.pointValueAmountForGeneralDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValuePercentageForInviter: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
          minValue:
            this.pointValuePercentageForInviterValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviterMax: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterMaxActive
          ),
          minValue:
            this.pointValuePercentageForInviterMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviterDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
        },
        pointValuePercentageForInviterApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
        },
        pointValuePercentageForInviterDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          )
        },
        pointValuePercentageForInviterDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation && this.pointValuePercentageForInviterDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForInviterDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation && this.pointValuePercentageForInviterDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForInvitee: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeValidation
          ),
          minValue:
            this.pointValuePercentageForInviteeValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviteeMax: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeMaxActive
          ),
          minValue:
            this.pointValuePercentageForInviteeMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviteeDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
        },
        pointValuePercentageForInviteeApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
        },
        pointValuePercentageForInviteeDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeValidation
          )
        },
        pointValuePercentageForInviteeDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeValidation && this.pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForInviteeDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeValidation && this.pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForGeneral: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation
          ),
          minValue:
            this.pointValuePercentageForGeneralValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForGeneralMax: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralMaxActive
          ),
          minValue:
            this.pointValuePercentageForGeneralMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForGeneralDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation
          ),
        },
        pointValuePercentageForGeneralApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation
          ),
        },
        pointValuePercentageForGeneralDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation
          )
        },
        pointValuePercentageForGeneralDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation && this.pointValuePercentageForGeneralDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForGeneralDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation && this.pointValuePercentageForGeneralDisburseTypeAfterFirstTransaction
          )
        },
        totalNumberDisbursePerProgram: {
          required: requiredIf(
            () => (this.totalNumberDisbursePerProgramActive || !this.totalAmountDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
          ),
          minValue:
            (this.totalNumberDisbursePerProgramActive || !this.totalAmountDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
              ? minValue(1)
              : true,
        },
        totalAmountDisbursePerProgram: {
          required: requiredIf(
            () => (this.totalAmountDisbursePerProgramActive || !this.totalNumberDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
          ),
          minValue:
            (this.totalAmountDisbursePerProgramActive || !this.totalNumberDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
              ? minValue(1)
              : true,
        },
        totalNumberDisbursePerUser: {
          required: requiredIf(
            () => (this.totalNumberDisbursePerUserActive || !this.totalAmountDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
          ),
          minValue:
            (this.totalNumberDisbursePerUserActive || !this.totalAmountDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
              ? minValue(1)
              : true,
        },
        totalAmountDisbursePerUser: {
          required: requiredIf(
            () => (this.totalAmountDisbursePerUserActive || !this.totalNumberDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
          ),
          minValue:
            (this.totalAmountDisbursePerUserActive || !this.totalNumberDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
              ? minValue(1)
              : true,
        },
      },
    }

    if (this.form.triggerBy?.value === constantData.TriggerType.ON_SCHEDULE) {
      validate = {
        form: {
          ...validate.form,
          scheduleDate: { required },
          scheduleTime: { required },
        },
      }
    } else {
      validate = {
        form: {
          ...validate.form,
          startDate: { required },
          startTime: { required },
          endDate: { required },
          endTime: { required },
        },
      }
    }

    return validate
  }

  get getOptionsTriggeredInvoicePaidAll(): GroupOption<string, string>[] {
    return this.isMemberUser
      ? this.constants.INVOICE_SOURCE_MEMBERSHIP
      : this.constants.INVOICE_SOURCE
  }

  get triggerOptionsNew(): LabelValue<string>[] {
    return controller.triggerData.map(trigger => ({
      ...trigger,
      $isDisabled: !this.useActionProgrammaticPoint.isParcelPointTrigger(
        <constantData.TriggerType>trigger.value
      ),
    }))
  }

  get getConditionForParcelPoint(): boolean {
    let isParcelPoint =
      (this.form.userTarget &&
        this.useActionProgrammaticPoint.isParcelPointUserTarget(
          this.form.userTarget.value
        )) ||
      this.triggerParcelPoint

    if (
      !this.useActionProgrammaticPoint.isParcelPointRelease(
        this.programDetail.purpose
      )
    ) {
      isParcelPoint = false
    }

    return isParcelPoint
  }

  get labelPointValue(): string {
    if (this.getConditionForParcelPoint) {
      return PARCEL_POINT_NAME
    }
    return 'Saldo Point'
  }

  get labelPointExpiration(): string {
    if (this.getConditionForParcelPoint) {
      return PARCEL_POINT_NAME
    }
    return 'Point'
  }

  get routeOptions(): Data[] {
    return routeController.cityData
  }

  get programDetail(): Program {
    return controller.programDetail
  }

  get attachmentName(): string | null {
    return this.form.fileUsers ? this.form.fileUsers.name : null
  }

  get pointPurposeOptions(): Array<LabelValue<string | undefined>> {
    return controller.pointPurposes.map(item => ({
      label: item.name || '',
      value: item.id,
    }))
  }

  get paymentMethodList(): { label: string; value: string }[] {
    return controller.paymentMethods.map(item => ({
      label: item,
      value: item,
    }))
  }

  get extraRulesTotalAmountValidation(): boolean {
    if (this.extraRulesActive) {
      if (this.totalAmountTransactionActive && !this.totalNumberTransactionActive) {
        return true
      }
      else if (this.totalAmountTransactionActive && this.totalNumberTransactionActive) {
        return true
      }
      else if (!this.totalAmountTransactionActive && this.totalNumberTransactionActive) {
        return false
      }
      return true
    }
    return false
  }

  get extraRulesTotalNumberValidation(): boolean {
    if (this.extraRulesActive) {
      if (this.totalNumberTransactionActive && !this.totalAmountTransactionActive) {
        return true
      }
      else if (this.totalNumberTransactionActive && this.totalAmountTransactionActive) {
        return true
      }
      else if (!this.totalNumberTransactionActive && this.totalAmountTransactionActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForInviterValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForInviterActive && !this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return true
      }
      else if (this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return true
      }
      else if (!this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return false
      }
      else if (!this.pointValueAmountForInviterActive && !this.pointValueAmountForInviteeActive && this.pointValueAmountForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForInviteeValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForInviteeActive && !this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return true
      }
      else if (this.pointValueAmountForInviteeActive && this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return true
      }
      else if (!this.pointValueAmountForInviteeActive && this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return false
      }
      else if (!this.pointValueAmountForInviteeActive && !this.pointValueAmountForInviterActive && this.pointValueAmountForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForGeneralValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForGeneralActive) {
        return true
      }
      else return false
    }
    return false
  }

  get pointValuePercentageForInviterValidation(): boolean {
    if (this.pointValuePercentage) {
      if ((this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviterMaxActive) && !this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return true
      }
      else if ((this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviterMaxActive) && this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return true
      }
      else if (!this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return false
      }
      else if (!this.pointValuePercentageForInviterActive && !this.pointValuePercentageForInviteeActive && this.pointValuePercentageForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValuePercentageForInviteeValidation(): boolean {
    if (this.pointValuePercentage) {
      if ((this.pointValuePercentageForInviteeActive || this.pointValuePercentageForInviteeMaxActive) && !this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return true
      }
      else if ((this.pointValuePercentageForInviteeActive || this.pointValuePercentageForInviteeMaxActive) && this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return true
      }
      else if (!this.pointValuePercentageForInviteeActive && this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return false
      }
      else if (!this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForInviterActive && this.pointValuePercentageForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValuePercentageForGeneralValidation(): boolean {
    if (this.pointValuePercentage) {
      if (this.pointValuePercentageForGeneralActive) {
        return true
      }
      else return false
    }
    return false
  }

  get isValidPointValueAmountInviter(): boolean | undefined {
    return (this.isValidPointAmountInviterPointDisburseType || this.isValidPointAmountInviterPointDisburseTypeShipmentStatus || this.isValidPointAmountInviterPointDisburseTypeSpecificDate) || ((this.$v.form.pointValueAmountForInviter?.$dirty && !this.$v.form.pointValueAmountForInviter?.required) || !this.$v.form.pointValueAmountForInviter?.minValue) || (this.$v.form.pointValueAmountForInviterDisburseTo?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTo?.required) || (this.$v.form.pointValueAmountForInviterApprovalType?.$dirty && !this.$v.form.pointValueAmountForInviterApprovalType?.required)
  }

  get isValidPointValuePercentageInviter(): boolean | undefined {
    return (this.isValidPointPercentageInviterPointDisburseType || this.isValidPointPercentageInviterPointDisburseTypeShipmentStatus || this.isValidPointPercentageInviterPointDisburseTypeSpecificDate) || ((this.$v.form.pointValuePercentageForInviter?.$dirty && !this.$v.form.pointValuePercentageForInviter?.required) || !this.$v.form.pointValuePercentageForInviter?.minValue) || ((this.$v.form.pointValuePercentageForInviterMax?.$dirty && !this.$v.form.pointValuePercentageForInviterMax?.required) || !this.$v.form.pointValuePercentageForInviterMax?.minValue) || (this.$v.form.pointValuePercentageForInviterDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTo?.required) || (this.$v.form.pointValuePercentageForInviterApprovalType?.$dirty && !this.$v.form.pointValuePercentageForInviterApprovalType?.required)
  }

  get isValidPointValuePercentageInvitee(): boolean | undefined {
    return (this.isValidPointPercentageInviteePointDisburseType || this.isValidPointPercentageInviteePointDisburseTypeShipmentStatus || this.isValidPointPercentageInviteePointDisburseTypeSpecificDate) || ((this.$v.form.pointValuePercentageForInvitee?.$dirty && !this.$v.form.pointValuePercentageForInvitee?.required) || !this.$v.form.pointValuePercentageForInvitee?.minValue) || ((this.$v.form.pointValuePercentageForInviteeMax?.$dirty && !this.$v.form.pointValuePercentageForInviteeMax?.required) || !this.$v.form.pointValuePercentageForInviteeMax?.minValue) || (this.$v.form.pointValuePercentageForInviteeDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTo?.required) || (this.$v.form.pointValuePercentageForInviteeApprovalType?.$dirty && !this.$v.form.pointValuePercentageForInviteeApprovalType?.required)
  }

  get isValidPointValueAmountInvitee(): boolean | undefined {
    return (this.isValidPointAmountInviteePointDisburseType || this.isValidPointAmountInviteePointDisburseTypeShipmentStatus || this.isValidPointAmountInviteePointDisburseTypeSpecificDate) || ((this.$v.form.pointValueAmountForInvitee?.$dirty && !this.$v.form.pointValueAmountForInvitee?.required) || !this.$v.form.pointValueAmountForInvitee?.minValue) || (this.$v.form.pointValueAmountForInviteeDisburseTo?.$dirty && !this.$v.form.pointValueAmountForInviteeDisburseTo?.required) || (this.$v.form.pointValueAmountForInviteeApprovalType?.$dirty && !this.$v.form.pointValueAmountForInviteeApprovalType?.required)
  }

  get isValidPointValuePercentageGeneral(): boolean | undefined {
    return ((this.$v.form.pointValuePercentageForGeneral?.$dirty && !this.$v.form.pointValuePercentageForGeneral?.required) || !this.$v.form.pointValuePercentageForGeneral?.minValue) || ((this.$v.form.pointValuePercentageForGeneralMax?.$dirty && !this.$v.form.pointValuePercentageForGeneralMax?.required) || !this.$v.form.pointValuePercentageForGeneralMax?.minValue) || (this.$v.form.pointValuePercentageForGeneralDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTo?.required) || (this.$v.form.pointValuePercentageForGeneralApprovalType?.$dirty && !this.$v.form.pointValuePercentageForGeneralApprovalType?.required)
  }

  get isValidPointValueAmountGeneral(): boolean | undefined {
    return ((this.$v.form.pointValueAmountForGeneral?.$dirty && !this.$v.form.pointValueAmountForGeneral?.required) || !this.$v.form.pointValueAmountForGeneral?.minValue) || (this.$v.form.pointValueAmountForGeneralDisburseTo?.$dirty && !this.$v.form.pointValueAmountForGeneralDisburseTo?.required) || (this.$v.form.pointValueAmountForGeneralApprovalType?.$dirty && !this.$v.form.pointValueAmountForGeneralApprovalType?.required)
  }

  get isValidPointAmountInviterPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseType?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseType?.required
  }

  get isValidPointAmountInviterPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTypeShipmentStatus?.required
  }

  get isValidPointAmountInviterPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTypeSpecificDate?.required
  }

  get isValidPointAmountInviteePointDisburseType(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviteeDisburseType?.$dirty && !this.$v.form.pointValueAmountForInviteeDisburseType?.required
  }

  get isValidPointAmountInviteePointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviteeDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValueAmountForInviteeDisburseTypeShipmentStatus?.required
  }

  get isValidPointAmountInviteePointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviteeDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValueAmountForInviteeDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageInviterPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviterDisburseType?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseType?.required
  }

  get isValidPointPercentageInviterPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviterDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageInviterPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageInviteePointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseType?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseType?.required
  }

  get isValidPointPercentageInviteePointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageInviteePointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageGeneralPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseType?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseType?.required
  }

  get isValidPointPercentageGeneralPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageGeneralPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.required
  }

  get pointPurposeValidation(): boolean {
    if (this.form.pointPurposeState.filter(item => item.checked).length) {
      return this.form.pointPurposeState.filter(item => item.checked).map(item => item.value > 0).includes(false)
    }
    return true
  }

  get pointValueAmount(): boolean {
    return this.form.pointValue?.value === constantData.PointValue.AMOUNT
  }

  get pointValuePercentage(): boolean {
    return this.form.pointValue?.value === constantData.PointValue.PERCENTAGE
  }

  get pointValueAmountForInviterDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForInviterDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForInviterDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForInviterDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValueAmountForInviteeDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForInviteeDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForInviteeDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForInviteeDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValueAmountForGeneralDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForGeneralDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForGeneralDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForGeneralDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviterDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForInviterDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviterDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForInviterDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValuePercentageForInviteeDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForInviteeDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForInviteeDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValuePercentageForGeneralDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForGeneralDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForGeneralDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForGeneralDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointDisburseTypeDirectlyAfterTrigger(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_TRIGGER
  }

  get pointDisburseTypeDirectlyAfterFirstTransaction(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get isUserTypeAllLogin(): boolean {
    return this.form.userTarget?.value === constantData.UserType.LOGIN
  }

  get isUserTypeWithReferral(): boolean {
    return this.form.userTarget?.value === constantData.UserType.WITH_REFERRAL
  }

  get isUserTypeWithoutReferral(): boolean {
    return this.form.userTarget?.value === constantData.UserType.WITHOUT_REFERRAL
  }

  get triggedByTransactionDone(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.TRANSACTION_DONE
  }

  get triggedByRegistration(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REGISTRATION
  }

  get triggedByReferralCodeUsed(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REFERRAL_CODE_USED
  }

  get triggedByReferralInvitee(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REFERRAL_INVITEE
  }

  get triggedByInvoicePaidAll(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.PAID_ALL
  }

  get triggedByShipmentPaidCity(): boolean {
    return (
      this.form.triggerBy?.value ===
      constantData.TriggerType.SHIPMENT_PAID_BY_CITY
    )
  }

  get isTriggeredByOnSchedule(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.ON_SCHEDULE
  }

  get setBgClassSwitcherBudgetAmount(): string {
    return this.budgetAmountDisabledOnEdit && this.budgetAmountActive
      ? 'bg-gray-2'
      : 'bg-bgPrimary'
  }

  get setBgClassSwitcherExtraRules(): string {
    return this.extraRulesDisabledOnEdit && this.extraRulesActive || this.programDetail.status !== 'PENDING'
      ? 'bg-gray-2'
      : 'bg-bgPrimary'
  }

  get budgetAmountError(): string {
    if (
      (this.$v.form.budgetAmount?.$dirty &&
        !this.$v.form.budgetAmount?.required) ||
      !this.$v.form.budgetAmount?.minValue
    ) {
      return `Budget amount can’t be 0`
    }

    // if (
    //   this.form.budgetAmount &&
    //   this.$v.form.pointAmount?.$dirty &&
    //   +this.form.budgetAmount < this.form.pointAmount
    // ) {
    //   return 'Budget amount must be higher than the point amount'
    // }

    if (
      this.form.budgetAmount &&
      this.form.maxAmountGiven &&
      this.$v.form.maxAmountGiven?.$dirty &&
      +this.form.budgetAmount < this.form.maxAmountGiven
    ) {
      return 'Budget amount must be higher than the point amount'
    }

    return ''
  }

  private setSaldoPurposePayload(value: string): void {
    if (this.form.purpose.includes(value)) {
      this.form.purpose = this.form.purpose.filter(item => item !== value)
    } else {
      this.form.purpose.push(value)
    }
  }

  private setInvoiceSourcePayload(value: string): void {
    if (this.form.invoiceSource.includes(value)) {
      this.form.invoiceSource = this.form.invoiceSource.filter(
        item => item !== value
      )
    } else {
      this.form.invoiceSource.push(value)
    }
  }

  get isMemberUser(): boolean {
    return !!(
      this.form.userTarget &&
      this.form.userTarget.value === constantData.UserType.MEMBERSHIP
    )
  }

  private setInvoiceSourcePayloadGroup(value: string): void {
    const group = this.getOptionsTriggeredInvoicePaidAll.find(
      source => source.group === value
    )
    if (
      group?.options?.every(
        option => !this.form.invoiceSource.includes(option.value)
      )
    ) {
      const groupOption: LabelValue<string>[] = group.options
      let newGroup = groupOption.map(option => option.value)

      if (this.isMemberUser) {
        newGroup = groupOption
          .filter(option => !option['disabled'])
          .map(option => option.value)
      }

      this.form.invoiceSource = [...this.form.invoiceSource, ...newGroup]
    } else {
      this.form.invoiceSource = this.form.invoiceSource.filter(
        item => !group?.options?.find(option => option.value === item)
      )
    }
  }

  private setPaymentMethodPayload(value: string): void {
    if (this.form.paymentMethod.includes(value)) {
      this.form.paymentMethod = this.form.paymentMethod.filter(
        item => item !== value
      )
    } else {
      this.form.paymentMethod.push(value)
    }
  }

  private onSaveProgram(): void {
    this.$v.$touch()

    if (!this.$v.form.$invalid) {
      const form = {
        ...this.form,
        isPercentage:
          this.form.pointValueType === constantData.PointValueType.PERCENTAGE,
        isExactDate: this.form.pointExpirationType === 'exact-date',
      }
      controller.sendProgram({
        ...form,
        programId: this.$route.params.id,
      })
    } else {
      this.$notify({
        title: 'Create Program',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'ProgramaticPointPage' })
  }

  get disableStartDate(): boolean {
    if (!this.form.triggerBy) {
      return true
    }

    if (this.form.triggerBy.value === constantData.TriggerType.ON_SCHEDULE) {
      return true
    }

    return this.programDetail.status !== 'PENDING'
  }

  get shipmentStatusOptions(): DropdownInterface[] {
    return this.constants.SHIPMENT_STATUS.map(shipmentStatus => ({
      ...shipmentStatus,
    }))
  }

  get specificDateOptions(): DropdownInterface[] {
    return this.constants.SPECIFIC_DATE.map(specificDate => ({
      ...specificDate,
    }))
  }

  get targetOptionsNew(): DropdownInterface[] {
    return this.constants.TARGET_OPTIONS.map(target => ({
      ...target,
      $isDisabled: !this.useActionProgrammaticPoint.isParcelPointUserTarget(
        <constantData.UserType>target.value
      ),
    }))
  }

  get pointValueOptionsNew(): DropdownInterface[] {
    if (
        (
          this.triggedByRegistration ||
          this.triggedByReferralCodeUsed ||
          this.triggedByReferralInvitee
        ) &&
        this.pointDisburseTypeDirectlyAfterTrigger
       ) {
      return this.constants.POINT_VALUE.map(pointValue => ({
        ...pointValue,
        $isDisabled: pointValue.value === constantData.PointValue.PERCENTAGE
      }))
    } else {
      return this.constants.POINT_VALUE.map(pointValue => ({
        ...pointValue,
      }))
    }
  }

  get InviterDisabled(): boolean {
    if (this.triggedByRegistration && (this.isUserTypeAllLogin || this.isUserTypeWithoutReferral)) {
      return true
    }
    else if (this.triggedByReferralInvitee && (this.isUserTypeAllLogin || this.isUserTypeWithReferral)) {
      return true
    }
    else {
      return false
    }
  }

  get InviteeDisabled(): boolean {
    if (this.triggedByRegistration && (this.isUserTypeAllLogin || this.isUserTypeWithoutReferral)) {
      return true
    }
    else if (this.triggedByReferralCodeUsed && (this.isUserTypeAllLogin || this.isUserTypeWithReferral)) {
      return true
    }
    else {
      return false
    }
  }

  get isInviteAndInviterPercentageChecked() : boolean {
    return this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviteeActive
  }

  get isInviteAndInviterAmountChecked() : boolean {
    return this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive
  }

  get isValidCheckedInviteInviter(): boolean {
    if (this.pointValuePercentageForGeneralActive) {
      return true
    }

    if (this.pointValuePercentage && (this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviteeActive)) {
      return this.isInviteAndInviterPercentageChecked
    }

    if (this.pointValueAmount && (this.pointValueAmountForInviterActive || this.pointValueAmountForInviteeActive)) {
      return this.isInviteAndInviterAmountChecked
    }

    return !this.pointValuePercentageForGeneralActive;
  }

  get GeneralDisabled(): boolean {
    if (this.triggedByRegistration && this.isUserTypeWithReferral) {
      return true
    }
    else if (
      (this.triggedByReferralCodeUsed || this.triggedByReferralInvitee) &&
      (this.isUserTypeAllLogin || this.isUserTypeWithReferral)
      ) {
      return true
    }
    else {
      return false
    }
  }

  get disburseTypeOptionsNew(): DropdownInterface[] {
    if (
      this.triggedByRegistration ||
      this.triggedByReferralCodeUsed ||
      this.triggedByReferralInvitee
    ) {
      if (this.pointValuePercentage || (this.isUserTypeWithoutReferral && this.triggedByRegistration)) {
        return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
          ...disburseType,
          $isDisabled: disburseType.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH || disburseType.value === constantData.PointDisburseType.DIRECTLY_AFTER_TRIGGER
        }))
      }
      else {
        return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
          ...disburseType,
          $isDisabled: disburseType.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
        }))
      }
    }
    else {
      return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
        ...disburseType,
      }))
    }
  }

  get triggerParcelPoint(): boolean {
    return (
      this.form.triggerBy !== null &&
      this.useActionProgrammaticPoint.isParcelPointTrigger(
        <constantData.TriggerType>this.form.triggerBy.value
      )
    )
  }

  private onChangeBudgetToggle(): void {
    this.budgetAmountActive = !this.budgetAmountActive
    this.form.budgetAmount = null
  }

  private onChangeExtraRulesToggle(): void {
    this.extraRulesActive = !this.extraRulesActive
    this.form.extraRulesActive = !this.form.extraRulesActive
    this.totalAmountTransactionActive = false
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.totalNumberTransactionActive = false
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private onChangeToggleMaxValidateDeviceId(): void {
    this.deviceIdLimitCustomerActive = !this.deviceIdLimitCustomerActive
    this.form.deviceIdLimitCustomer = null
  }

  private onChangeTotalAmountTransactionToggle(): void {
    this.totalAmountTransactionActive = !this.totalAmountTransactionActive
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.form.logicType = ''
  }

  private onChangeTotalNumberTransactionToggle(): void {
    this.totalNumberTransactionActive = !this.totalNumberTransactionActive
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private onChangePointValueAmountForInviterToggle(): void {
    this.pointValueAmountForInviterActive = !this.pointValueAmountForInviterActive
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForInviterDisburseTypeSpecificDate = null
  }

  private onChangePointValueAmountForInviteeToggle(): void {
    this.pointValueAmountForInviteeActive = !this.pointValueAmountForInviteeActive
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForInviteeDisburseTypeSpecificDate = null
  }

  private onChangePointValueAmountForGeneralToggle(): void {
    this.pointValueAmountForGeneralActive = !this.pointValueAmountForGeneralActive
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviterToggle(): void {
    this.pointValuePercentageForInviterActive = !this.pointValuePercentageForInviterActive
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterApprovalType = null
    this.form.pointValuePercentageForInviterDisburseTo = null
    this.form.pointValuePercentageForInviterDisburseTo = null
    this.form.pointValuePercentageForInviterDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForInviterDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviterMaxToggle(): void {
    this.pointValuePercentageForInviterMaxActive = !this.pointValuePercentageForInviterMaxActive
    this.form.pointValuePercentageForInviterMax = null
  }

  private onChangePointValuePercentageForInviteeToggle(): void {
    this.pointValuePercentageForInviteeActive = !this.pointValuePercentageForInviteeActive
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeApprovalType = null
    this.form.pointValuePercentageForInviteeDisburseTo = null
    this.form.pointValuePercentageForInviteeDisburseTo = null
    this.form.pointValuePercentageForInviteeDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForInviteeDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviteeMaxToggle(): void {
    this.pointValuePercentageForInviteeMaxActive = !this.pointValuePercentageForInviteeMaxActive
    this.form.pointValuePercentageForInviteeMax = null
  }

  private onChangePointValuePercentageForGeneralToggle(): void {
    this.pointValuePercentageForGeneralActive = !this.pointValuePercentageForGeneralActive
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralApprovalType = null
    this.form.pointValuePercentageForGeneralDisburseTo = null
    this.form.pointValuePercentageForGeneralDisburseTo = null
    this.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForGeneralMaxToggle(): void {
    this.pointValuePercentageForGeneralMaxActive = !this.pointValuePercentageForGeneralMaxActive
    this.form.pointValuePercentageForGeneralMax = null
  }

  private resetConditionAffectByTrigger(): void {
    this.pointValueAmountForInviterActive = false
    this.pointValueAmountForInviteeActive = false
    this.pointValueAmountForGeneralActive = false
    this.pointValuePercentageForInviterActive = false
    this.pointValuePercentageForInviterMaxActive = false
    this.pointValuePercentageForInviteeActive = false
    this.pointValuePercentageForInviteeMaxActive = false
    this.pointValuePercentageForGeneralActive = false
    this.pointValuePercentageForGeneralMaxActive = false
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterMax = null
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeMax = null
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralMax = null
    this.form.pointValue = null
    this.form.pointDisburseType = null
    this.form.shipmentStatus = null
    this.form.specificDate = null
    this.triggeredByHasChanged = true
    this.extraRulesActive = false
    this.form.extraRulesActive = false
    this.totalAmountTransactionActive = false
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.totalNumberTransactionActive = false
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private resetOnChangedPointValue(): void {
    this.pointValueAmountForInviterActive = false
    this.pointValueAmountForInviteeActive = false
    this.pointValueAmountForGeneralActive = false
    this.pointValuePercentageForInviterActive = false
    this.pointValuePercentageForInviterMaxActive = false
    this.pointValuePercentageForInviteeActive = false
    this.pointValuePercentageForInviteeMaxActive = false
    this.pointValuePercentageForGeneralActive = false
    this.pointValuePercentageForGeneralMaxActive = false
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterMax = null
    this.form.pointValuePercentageForInviterDisburseTo = null
    this.form.pointValuePercentageForInviterApprovalType = null
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeMax = null
    this.form.pointValuePercentageForInviteeDisburseTo = null
    this.form.pointValuePercentageForInviteeApprovalType = null
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralMax = null
    this.form.pointValuePercentageForGeneralDisburseTo = null
    this.form.pointValuePercentageForGeneralApprovalType = null
  }

  private resetOnChangedDisburseType(): void {
    this.form.shipmentStatus = null
    this.form.specificDate = null
  }

  private resetOnChangedDisburseProgram(): void {
    this.totalNumberDisbursePerProgramActive = false
    this.form.totalNumberDisbursePerProgram = null
    this.totalAmountDisbursePerProgramActive = false
    this.form.totalAmountDisbursePerProgram = null
  }

  private resetOnChangedDisburseUser(): void {
    this.totalNumberDisbursePerUserActive = false
    this.form.totalNumberDisbursePerUser = null
    this.totalAmountDisbursePerUserActive = false
    this.form.totalAmountDisbursePerUser = null
  }

  private onChangeTotalNumberDisbursePerProgramToggle(): void {
    this.totalNumberDisbursePerProgramActive = !this.totalNumberDisbursePerProgramActive
    this.form.totalNumberDisbursePerProgram = null
  }

  private onChangeTotalAmountDisbursePerProgramToggle(): void {
    this.totalAmountDisbursePerProgramActive = !this.totalAmountDisbursePerProgramActive
    this.form.totalAmountDisbursePerProgram = null
  }

  private onChangeTotalNumberDisbursePerUserToggle(): void {
    this.totalNumberDisbursePerUserActive = !this.totalNumberDisbursePerUserActive
    this.form.totalNumberDisbursePerUser = null
  }

  private onChangeTotalAmountDisbursePerUserToggle(): void {
    this.totalAmountDisbursePerUserActive = !this.totalAmountDisbursePerUserActive
    this.form.totalAmountDisbursePerUser = null
  }

  private onChangePointPurposeStateToggle(index: number): void {
    this.form.pointPurposeState[index].checked = !this.form.pointPurposeState[index].checked
    this.form.pointPurposeState[index].value = this.form.pointPurposeState[index].checked ? 100 : 0
    this.pointPurposeChanged = true
  }

  private purposeLabel(purpose: { value: string, label: string }): string {
    switch (purpose.value) {
      case 'PICKUP':
        return 'Pick Up'
      case 'DROPOFF':
        return 'Drop Paket'
      case 'SHOP':
        return 'BisaBelanja'
      case 'PICKUP_FAVORITE':
        return 'Pick Up Favorite'
      case 'DROPOFF_FAVORITE':
        return 'Drop Off Favorite'
      case 'REDEEM_VOUCHER':
        return 'Redeem Voucher'
      default:
        return purpose.label
    }
  }

  private onCancel(): void {
    this.showModalConfirm = true
  }

  private goBack(): void {
    this.showModalConfirm = false
  }

  private doActionConfirm(): void {
    this.$router.push('/saldo')
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.SAVE_PROGRAM_SUCCESS)
  }
}
